import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'surgicalGuideCheckbox',
    'printingServiceCheckbox',
    'cbRestorationCheckbox',
    'cbPonticCheckbox',
    'irAbutmentCheckbox',
    'irCrownCheckbox',
    'irPonticCheckbox',
    'irOcclusionCheckbox',
    'irContactsCheckbox',
    'cbOcclusionCheckbox',
    'cbContactsCheckbox'
  ];

  initialize() {
    const checkboxTargets = [
      'surgicalGuideCheckbox',
      'printingServiceCheckbox',
      'cbRestorationCheckbox',
      'cbPonticCheckbox',
      'irAbutmentCheckbox',
      'irCrownCheckbox',
      'irPonticCheckbox',
      'irOcclusionCheckbox',
      'irContactsCheckbox',
      'cbOcclusionCheckbox',
      'cbContactsCheckbox'
    ]

    checkboxTargets.forEach((cb) => {
      this.validatePresence()
    })
  }

  validatePresence() {
    if (this.hasSurgicalGuideCheckboxTarget) {
      var checkboxValues = this.surgicalGuideCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.surgicalGuideCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.surgicalGuideCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasPrintingServiceCheckboxTarget) {
      var checkboxValues = this.printingServiceCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.printingServiceCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.printingServiceCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasCbRestorationCheckboxTarget) {
      var checkboxValues = this.cbRestorationCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.cbRestorationCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.cbRestorationCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasCbPonticCheckboxTarget) {
      var checkboxValues = this.cbPonticCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.cbPonticCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.cbPonticCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasIrAbutmentCheckboxTarget) {
      var checkboxValues = this.irAbutmentCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.irAbutmentCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.irAbutmentCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasIrCrownCheckboxTarget) {
      var checkboxValues = this.irCrownCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.irCrownCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.irCrownCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasIrPonticCheckboxTarget) {
      var checkboxValues = this.irPonticCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.irPonticCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.irPonticCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasIrOcclusionCheckboxTarget) {
      var checkboxValues = this.irOcclusionCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.irOcclusionCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.irOcclusionCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasIrContactsCheckboxTarget) {
      var checkboxValues = this.irContactsCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.irContactsCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.irContactsCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasCbOcclusionCheckboxTarget) {
      var checkboxValues = this.cbOcclusionCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.cbOcclusionCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.cbOcclusionCheckboxTargets.forEach((cb) => cb.required = true)
      }
    } else if (this.hasCbContactsCheckboxTarget) {
      var checkboxValues = this.cbContactsCheckboxTargets.map((cb) => cb.checked)
      if (checkboxValues.includes(true)) {
        this.cbContactsCheckboxTargets.forEach((cb) => cb.required = false)
      } else {
        this.cbContactsCheckboxTargets.forEach((cb) => cb.required = true)
      }
    }
  }
}
