import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['attachments', 'value'];

  addAttachmentContent(data) {
    var formattedName = document.getElementById("solution").innerHTML
    formattedName = formattedName.replace(' Details', '')
    formattedName = formattedName.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');

    var attachments = data.attachments.split(',')
    attachments.forEach((attachment) => {
      document.getElementById("attachment-checkbox").innerHTML += `
      <input id="${attachment}" type="hidden" name="${`case[solution_options[${formattedName}][attachments][${attachment}]]`}" data-attachment-modal-target="value" value="0">
      <input id="${attachment}" type="checkbox" name="${`case[solution_options[${formattedName}][attachments][${attachment}]]`}" data-attachment-modal-target="value" value="1">
      <label id="${attachment}" data-attachment-modal-target="name">${attachment}</label> `
    })
  }

  removeAttachmentContent() {
    document.getElementById("attachment-checkbox").innerHTML = ""
  }
}
