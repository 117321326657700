import {Controller} from "stimulus"

class CaseValidationController extends Controller {
    static targets = ['form', 'openController'];

    validateForm() {
        if (this.formTarget.reportValidity()) {
            let openController = this.application.getControllerForElementAndIdentifier(
                this.openControllerTarget,
                "open"
            );
            openController.openModal();
        }
    }
}

export default CaseValidationController