import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['pickupDatepicker', 'pickupEnabledRadio'];

  connect() {
    if (this.pickupEnabledRadioTarget.checked && this.data.get("mode") !== 'show') {
      this.pickupDatepickerTarget.removeAttribute("disabled")
    } else {
      this.pickupDatepickerTarget.setAttribute("disabled", null)
    }
  }

  updateDatepickerStatus(changeEvent) {
    if (event.target.value === 'true') {
      this.pickupDatepickerTarget.removeAttribute("disabled")
    } else {
      this.pickupDatepickerTarget.setAttribute("disabled", null)
    }
  }
}
