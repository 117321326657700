import {  Controller } from "stimulus"

export default class extends Controller {
  static targets = ['deleteModal'];

  open() {
    this.deleteModalTarget.classList.add("show");
  }

  close() {
    this.deleteModalTarget.classList.remove("show");
  }
}
