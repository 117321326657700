import {  Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'profileNavOpen',
    'signOutBtn',
    'profileBtn',
    'sideNavOpen',
    'signOutBtn',
    'sideNav',
    'sideNavOpen',
    'actionsLeft',
    'sideNavClose',
    'buttonWithDropdown',
    'profileNavigation',
    'messageNavigation',
    'messageNavOpen',
    'logoHeader',
    'dropDownNavClose',
    'advancedFilters',
    "advancedFiltersNavigation",
    "mainContent"
  ]

  initialize() {
    if (this.hasSideNavTarget) {
      this.updateButtons();
    }
  };

  // Triggered during page loads and window resizing
  updateButtons() {
    const screenWidth = $(window).width();
    if (screenWidth <= 1024 ) { 
      // Mobile View
      this.collapseSideNav();
      this.closeDropDownNav();
      // Top Nav Buttons
      this.profileNavOpenTarget.classList.add('hide')
      // Side Nav Buttons
      this.signOutBtnTarget.classList.remove("hide")
      this.profileBtnTarget.classList.remove("hide")
    } else { // Desktop
      // Open Side Nav
      this.sideNavTarget.classList.add("is-open") // Always Open in Desktop
      // Top Nav Buttons
      this.profileNavOpenTarget.classList.remove('hide')
      this.sideNavOpenTarget.classList.add("hide")
      // Side Nav Buttons
      this.signOutBtnTarget.classList.add("hide")
      this.profileBtnTarget.classList.add("hide")
    };
  };

  // Opens Side Nav
  // Hides Top Nav => Left Action Section (Open Side Nav) &&  Right Actions Button with Dropdown Section (Message Button)
  // Displays Top Nav => Side Nav Close Button
  // Hides Main Content in mobile
  expandSideNav() {
    this.sideNavTarget.classList.add("is-open")
    this.actionsLeftTarget.classList.add("hide")
    this.sideNavCloseTarget.classList.remove("hide")
    this.buttonWithDropdownTarget.classList.add("hide")
    if ($(window).width() <= 1024) {
        this.mainContentTarget.classList.add("hide")
    }
  };

  // Closes Side Nav
  // Shows Top Nav => Left Action Section (Open Side Nav) &&  Right Actions Button with Dropdown Section (Message Button)
  // Hide Top Nav => Side Nav Close Button
  // Shows Main Content in mobile
  collapseSideNav() {
    this.sideNavCloseTarget.classList.add("hide")
    this.sideNavTarget.classList.remove("is-open")
    this.actionsLeftTarget.classList.remove("hide")
    this.sideNavOpenTarget.classList.remove("hide")
    this.buttonWithDropdownTarget.classList.remove("hide")
    if ($(window).width() <= 1024) {
      this.mainContentTarget.classList.remove("hide")
    }
  };

  toggleMessageNav() {
    const screenWidth = $(window).width();
    if (screenWidth <= 768 ) {
      this.dropDownNavCloseTarget.classList.toggle("hide")
      this.messageNavOpenTarget.classList.toggle("hide")
      this.sideNavOpenTarget.classList.toggle("hide")
    }
    this.messageNavigationTarget.classList.toggle("is-open")
  };

  toggleProfileNav(){
    this.profileNavigationTarget.classList.toggle("is-open")
  };

  // Hides Logo & Buttons With Dropdown (messages) & Side Nav Expansion from TopNav
  // Displays Advanced Filters Header & Close Drop Down Nav (back arrow),
  openAdvancedFiltersNav() {
    this.advancedFiltersNavigationTarget.classList.add("is-open")
    this.sideNavOpenTarget.classList.add("hide")
    this.logoHeaderTarget.classList.add("hide")

    this.dropDownNavCloseTarget.classList.remove("hide")
    this.advancedFiltersTarget.classList.remove("hide")
    this.buttonWithDropdownTarget.classList.add("hide")
  };

  resetHeaderLogo(){
    this.logoHeaderTarget.classList.remove("hide")
    this.advancedFiltersTarget.classList.add("hide")
  };

  closeDropDownNav(){
    this.messageNavigationTarget.classList.remove("is-open")
    this.advancedFiltersNavigationTarget.classList.remove("is-open")
    this.profileNavigationTarget.classList.remove("is-open")
    this.sideNavOpenTarget.classList.remove("hide")
    this.dropDownNavCloseTarget.classList.add("hide")
    this.messageNavOpenTarget.classList.remove("hide")
    this.resetHeaderLogo();
  };
}