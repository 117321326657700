// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'controllers'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import Dropzone from "dropzone";

// Dropzone settings
Dropzone.autoDiscover = false;

//Select2
// https://select2.org/
require("../vendor/select2")