import { Controller } from "stimulus";

class OpenController extends Controller {
  static targets = ['modal'];

  populateSolutionRequiredAttachments() {
    if (document.getElementById("attachment-checkbox") && document.getElementsByClassName("surgical-guide-options-wrapper").length > 0) {
      if (document.getElementById("attachment-checkbox").innerHTML == "") {
        const selectedGuideOption = Array.from(
            document.getElementsByClassName("surgical-guide-options-wrapper")[0]
                .getElementsByTagName("input")).filter(x => x.checked)[0].parentNode;

        let attachmentModalController = this.application.getControllerForElementAndIdentifier(
            this.modalTarget,
            "attachment-modal"
        );

        attachmentModalController.removeAttachmentContent();
        attachmentModalController.addAttachmentContent(selectedGuideOption.dataset);
      }
    }
  }

  updateRequirements(event) {
    let attachmentModalController = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        "attachment-modal"
    );

    if (event.target.checked) {
      attachmentModalController.removeAttachmentContent();
      attachmentModalController.addAttachmentContent(event.currentTarget.dataset);
    }
  }

  openModal() {
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        "modal"
    );

    this.populateSolutionRequiredAttachments();

    modalController.open();
  }
}

export default OpenController