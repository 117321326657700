import { Controller } from "stimulus"
import { get, put } from '@rails/request.js'
import { Turbo } from '@hotwired/turbo-rails'
import { debounce } from 'debounce'
window.Turbo = Turbo

export default class extends Controller {
  static targets = [ "doctor", "admin", "search" ]
  connect() {
    this.searchDoctors = debounce(this.searchDoctors.bind(this), 300)
    this.doctorExpanded = false
    this.adminExpanded = false

    const url = window.location.href
    this.caseId = url.match(/\/(\d+)/)[1]
  }

  showCheckbox() {
    if (this.hasDoctorTarget) {
      this.doctorExpanded ? this.doctorTarget.style.display = "none" : this.doctorTarget.style.display = "block"
      this.doctorExpanded = !this.doctorExpanded
    } else if (this.hasAdminTarget) {
      this.adminExpanded ? this.adminTarget.style.display = "none" : this.adminTarget.style.display = "block"
      this.adminExpanded = !this.adminExpanded
    }
  }

  searchDoctors() {
    get(
      `/users?query=${this.searchTarget.value}&case_id=${this.caseId}&category=${3}`,
      {
        responseKind: 'turbo-stream',
      }
    )
  }

  updateCollaborators() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const createCollaborator = event.target.checked ? 2 : 3
    const params = {
      user_id: event.target.value,
      case_id: this.caseId,
      category: createCollaborator
    }

    this.searchTarget.value = ""

    put(`/cases/${this.caseId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      responseKind: 'turbo-stream',
      body: JSON.stringify(params)
    })
  }
}
